import { Injectable } from '@angular/core';
import { ConfigService } from '@app/services/config.service';
import { CompanyService, UserService } from '@app/shared/services';
import { User } from '@app/shared/types/classes';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AnalyticsBrowser, Context } from '@segment/analytics-next';
import {
  AnalyticsCalculatedEventProperties,
  IAnalyticsEventProperties,
} from '@app/shared/types/interfaces/analytics-event-properties.interface';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { IAnalyticsUserProfile } from '@app/shared/types/interfaces/analytics-user-profile.interface';
import { IAnalyticsCompanyProfile } from '@app/shared/types/interfaces/analytics-company-profile.interface';
import { ICompany } from '@wevestr/bff-types/models/interfaces/company.interface';
import { IStakeholder } from '@app/types/interfaces/profile.interface';
import { CompanyAccountType } from '@wevestr/bff-types/models/enums/companyAccountType.enum';
import { ChannelName } from '@app/shared/types/enums/channel-name.enum';
import { forkJoin, Observable, of } from 'rxjs';
import { CookieService } from '@app/shared/services/cookie.service';
import { CookieName } from '@app/shared/types/enums/cookie-name.enum';
import { ViewType } from '@app/shared/types/enums/view-type.enum';
import { CompanyRole } from '@wevestr/bff-types/enums/companyRole.enum';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private token: string;
  private channelName: ChannelName;
  private analytics: AnalyticsBrowser;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private companyService: CompanyService,
    private cookieService: CookieService,
  ) {}

  public init(): void {
    this.token = this.configService.getSegmentToken();
    if (this.token) {
      this.analytics = AnalyticsBrowser.load({ writeKey: this.token });
      this.channelName = this.configService.isDemoModeEnabled() ? ChannelName.Demo : ChannelName.Platform;
      this.trackPageViews();
    }
  }

  public track(
    event: string,
    receivedProperties?: Omit<IAnalyticsEventProperties, AnalyticsCalculatedEventProperties>,
  ): void {
    if (this.analytics) {
      const viewType = this.getViewType();
      const user = this.userService.user;
      const companyId = this.userService.currentCompanyId;
      const groupId = this.getGroupId(companyId);
      const email = user?.email;
      let stakeholderRole: CompanyRole;

      if (user && companyId) {
        const stakeholder = this.userService.getCurrentStakeholder(user, companyId);
        stakeholderRole = stakeholder?.companyRole;
      }

      this.getCompanyProfileById$(companyId).subscribe((companyProfile: Partial<IAnalyticsCompanyProfile>) => {
        const calculatedProperties: Pick<IAnalyticsEventProperties, AnalyticsCalculatedEventProperties> = {
          email,
          stakeholderRole,
          viewType,
          channelName: this.channelName,
          groupId,
          companyProfile: companyProfile,
        };
        const eventProperties: IAnalyticsEventProperties = {
          ...receivedProperties,
          ...calculatedProperties,
        };

        this.analytics.track(event, eventProperties, { groupId });
      });
    }
  }

  public identify$(id: string, properties: IAnalyticsUserProfile): Observable<Promise<Context> | null> {
    if (this.analytics) {
      return of(this.analytics.identify(id, properties));
    }
    return of(null);
  }

  public group$(company: ICompany): Observable<Promise<Context> | null> {
    if (this.analytics) {
      return this.createCompanyProfile$(company).pipe(
        map((companyProfile) => this.analytics.group(company.id.toString(), companyProfile)),
      );
    }
    return of(null);
  }

  public setProfiles$(): Observable<[Promise<Context> | null, Promise<Context> | null]> {
    return this.userService.get().pipe(
      withLatestFrom(this.userService.currentCompanyId$),
      first(),
      switchMap(([user, companyId]: [User, number]) => {
        const stakeholder = this.userService.getCurrentStakeholder(user, companyId);
        return this.companyService.get(companyId, true).pipe(
          first(),
          switchMap((company) => this.identifyAndGroup$(user, stakeholder, company as unknown as ICompany)),
        );
      }),
    );
  }

  private identifyAndGroup$(
    user: User,
    stakeholder: IStakeholder,
    company: ICompany,
  ): Observable<[Promise<Context>, Promise<Context>]> {
    if (this.analytics) {
      return forkJoin([
        this.identify$(`${user.id}`, this.createUserProfile(user, stakeholder, company.id)),
        this.group$(company),
      ]);
    }
    return of([null, null]);
  }

  private trackPageViews(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const companyId = this.userService.currentCompanyId;
      const groupId = this.getGroupId(companyId);
      this.getCompanyProfileById$(companyId).subscribe((companyProfile) => {
        this.analytics.page(
          {
            channelName: this.channelName,
            groupId,
            companyProfile,
          },
          { groupId },
        );
      });
    });
  }

  private createUserProfile(user: User, stakeholder: IStakeholder, companyId: number): IAnalyticsUserProfile {
    const accessLevel = user.profile.roles.find((role) => role.companyId === companyId)?.role;
    const country = user.profile.country?.name;
    const numberOfCompanies = user.profile.roles?.length;

    return {
      email: user.email,
      userRole: stakeholder.companyRole,
      accountType: stakeholder.type,
      access: stakeholder.access,
      accessLevel,
      country,
      numberOfCompanies,
    };
  }

  private createCompanyProfile$(company: ICompany): Observable<Partial<IAnalyticsCompanyProfile>> {
    return company
      ? of({
          companyName: company.name,
          country: company.location?.name,
          industry: company.industry,
          partnershipName: company.entryPage,
          yearFounded: company.foundedYear,
          onboardingMethod: company.onboardingType,
          isInternal: company.isInternal,
          createdAt: company.createdAt,
          updatedAt: company.updatedAt,
          companyAccountType: CompanyAccountType.Company,
        })
      : this.route.queryParams.pipe(
          first(),
          map((params) => ({ partnershipName: params.campaign })),
        );
  }

  private getCompanyProfileById$(companyId: number): Observable<Partial<IAnalyticsCompanyProfile>> {
    const company$ = companyId ? this.companyService.get(companyId, true).pipe(first()) : of(null);
    return company$.pipe(switchMap((company) => this.createCompanyProfile$(company as unknown as ICompany)));
  }

  private getGroupId(companyId: number): string | undefined {
    return companyId?.toString() || this.cookieService.get(CookieName.AnalyticsGroupId) || undefined;
  }

  private getViewType(): ViewType {
    return this.router.url.startsWith('/participant') ? ViewType.Participant : ViewType.Admin;
  }
}
