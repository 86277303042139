<div class="flex-column position-relative sidenav-entry" [ngClass]="{ 'sidenav-item--active': isActive }">
  <a
    class="sidenav-item level level-left padding-left-l"
    [ngClass]="{
      'sidenav-item--disabled': option.disabled,
      'sidenav-item--collapsed': isSideNavCollapsed,
      'sidenav-item--grayout': option.grayout
    }"
    [routerLink]="
      (option.disabled && null) || option.children ? '/' + option.children[0].routerLink : '/' + option.routerLink
    "
    (click)="handleSidenavItemClicked()"
  >
    <div
      class="flex-row sidenav-item__content"
      [matTooltip]="option.description"
      [matTooltipDisabled]="!option.description"
      [matTooltipClass]="['info-tooltip', 'info-tooltip_top-alignment']"
      matTooltipPosition="right"
      [ngClass]="{ 'sidenav-item__content--collapsed': isSideNavCollapsed }"
    >
      <mat-icon
        class="margin-right-s sidenav-item__icon"
        [svgIcon]="option.icon"
        [ngClass]="{ 'sidenav-item__icon--collapsed': isSideNavCollapsed, 'sidenav-item--grayout': option.grayout }"
      >
      </mat-icon>
      <h5 class="sidenav-item__name app-h5" [ngClass]="{ 'sidenav-item__name--collapsed': isSideNavCollapsed }">
        {{ option.name }}
      </h5>
      <div *ngIf="option.badge && !isSideNavCollapsed" class="margin-left-m sidenav-item__new-label" [ngStyle]="{'background-color': option.badge.color}">
        {{ option.badge.label | uppercase }}
      </div>
      <mat-icon class="sidenav-item__arrow" *ngIf="option.children" svgIcon="dropdown"> </mat-icon>
    </div>
  </a>

  <div
    class="flex-column sidenav__child-group"
    *ngIf="option.children && !isSideNavCollapsed && (option.expanded || this.isActive)"
  >
    <a
      class="sidenav-item__child"
      *ngFor="let child of option.children"
      [ngClass]="{ 'sidenav-item__child--active': subcategoryActive(child) }"
      [routerLink]="'/' + child.routerLink"
    >
      {{ child.name }}
    </a>
  </div>
</div>
