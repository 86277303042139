<form class="security-form flex-column" *ngIf="form">
  <div class="form" [formGroup]="form" *wvLet="showPassword ? 'text' : 'password' as passwordType">
    <div class="form-field margin-bottom-l">
      <wevestr-input
        class="input-container password-input"
        [type]="passwordType"
        [showAllErrors]="false"
        [label]="SECURITY_FORM_DETAILS.PASSWORD.label"
        [showValidFieldStyle]="false"
        [showInvalidFieldStyle]="false"
        [showValidityImmediately]="wasPasswordSetBefore"
        formControlName="password"
      >
        <button
          addon
          [tabindex]="ignoreTabIndex"
          class="button-reset-styles addon"
          (click)="togglePasswordVisibility()"
        >
          <mat-icon [svgIcon]="showPassword ? PASSWORD_ICONS.invisible : PASSWORD_ICONS.visible"></mat-icon>
        </button>
        <ng-container errors>
          <div class="error-message app-body-small-const" wvFormError="required">{{ ERRORS.REQUIRED }}</div>
          <div class="error-message app-body-small-const" wvFormError="minlength">{{ ERRORS.MIN_LENGTH }}</div>
          <div class="error-message app-body-small-const" wvFormError="badPasswordCharacters">{{ ERRORS.PATTERN }}</div>
        </ng-container>
      </wevestr-input>
    </div>
    <div class="form-field margin-bottom-m">
      <wevestr-input
        class="input-container password-input"
        [type]="passwordType"
        [showAllErrors]="false"
        [label]="SECURITY_FORM_DETAILS.CONFIRM_PASSWORD.label"
        [showValidFieldStyle]="false"
        [showInvalidFieldStyle]="false"
        formControlName="confirmPassword"
      >
        <button
          addon
          [tabindex]="ignoreTabIndex"
          class="button-reset-styles addon"
          (click)="togglePasswordVisibility()"
        >
          <mat-icon [svgIcon]="showPassword ? PASSWORD_ICONS.invisible : PASSWORD_ICONS.visible"></mat-icon>
        </button>
        <ng-container errors>
          <div class="error-message app-body-small-const" wvFormError="noMatch">{{ ERRORS.MATCH }}</div>
        </ng-container>
      </wevestr-input>
    </div>
    <div class="margin-bottom-l">
      <div
        class="flex-row justify-content-space-between align-items-center margin-bottom-m"
        *ngIf="passwordStrength$ | async as passwordStrength"
      >
        <div class="flex-row justify-content-space-between">
          <div class="line one-third" [ngClass]="passwordStrength"></div>
          <div class="line one-third" [ngClass]="passwordStrength"></div>
          <div class="line two-thirds" [ngClass]="passwordStrength"></div>
          <div class="line two-thirds" [ngClass]="passwordStrength"></div>
          <div class="line" [ngClass]="passwordStrength"></div>
          <div class="line" [ngClass]="passwordStrength"></div>
        </div>
        <div class="strength-title app-body-bold" [ngClass]="passwordStrength">
          {{ passwordStrength | titlecase }}
        </div>
      </div>
      <div *ngIf="!form.controls.password.valid && !form.controls.password.pristine" class="flex-column">
        <p class="hint app-body-small">Length must be greater than 8 characters;</p>
        <p class="hint app-body-small">Password must contain numbers;</p>
        <p class="hint app-body-small">Password must contain uppercase letters;</p>
        <p class="hint app-body-small">Password must contain lowercase letters;</p>
      </div>
    </div>
    <div class="flex">
      <wevestr-checkbox class="margin-right-s" type="blue" formControlName="confirmPolicy"></wevestr-checkbox>
      <p class="app-body-medium-weight policy">
        I have read and agree to the
        <a
          class="anchor anchor--with-decoration"
          [href]="privacyPolicyUrl"
          target="_blank"
          (click)="handlePrivacyPolicyClick($event)"
          >Privacy Policy</a
        >,
        <a
          class="anchor anchor--with-decoration"
          [href]="cookieUrl"
          target="_blank"
          (click)="handleCookiePolicyClick($event)"
          >Cookie Policy</a
        >
        and
        <a
          class="anchor anchor--with-decoration"
          [href]="termsAndConditionsUrl"
          target="_blank"
          (click)="handleTermsAndConditionsClick($event)"
          >Terms & Conditions</a
        >
        of WeVestr Coöperatie U.A. to receive email updates.
      </p>
    </div>
  </div>
  <div class="margin-top-l flex-column align-items-center">
    <button
      (click)="submitSecurity()"
      [disabled]="form.invalid"
      class="button primary-button app-body full-width"
      type="submit"
    >
      {{ continueButtonTitle || BUTTONS.CONTINUE }}
    </button>

    <div *ngIf="isBackButtonVisible" (click)="handleBack()" class="back-button margin-top-l">
      {{ BUTTONS.BACK }}
    </div>
  </div>
</form>
