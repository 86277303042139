import { Params } from '@angular/router';

import { TABLE_DETAILS_STYLE } from '@app/shared/utils/helpers/table.helpers';
import { IDisplayableDocument } from '@app/documents/types/interfaces';
import { DocumentsRouteNames } from '@app/documents/types/enums/documents-route-names.enum';
import { ITab } from '@app/shared/types/interfaces';

export const DOCUMENT_BREADCRUMB_ROOTS = {
  COMPANY: 'Company',
  PERSONAL: 'Personal',
  ESOP: 'ESOP',
  ONBOARDING: 'Onboarding Files',
};

export const FOLDER_MODAL_MODES = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const DELETE_FOLDERS_MODAL_MAX_WIDTH = '525px';

export const ROOT_FOLDER_ID = 0;

export const EMPTY_STATE_COPY = {
  title: 'Nothing uploaded yet!',
  text: 'Start creating or uploading documents to keep track of them.',
  displayImage: true,
};

export const SELECT_FILES_PLACEHOLDER = 'Select Files';
export const EXTENSION_ICONS: { [key: string]: string } = {
  doc: 'file_type_word',
  docx: 'file_type_word',
  xls: 'file_type_excel',
  xlsx: 'file_type_excel',
  ods: 'file_type_spreadsheets',
  numbers: 'file_type_spreadsheets',
  jpeg: 'file_type_image',
  jpg: 'file_type_image',
  svg: 'file_type_image',
  png: 'file_type_image',
  webp: 'file_type_image',
  zip: 'file_type_zip',
  pdf: 'file_type_pdf',
  ppt: 'file_type_powerpoint',
  pptx: 'file_type_powerpoint',
  potx: 'file_type_powerpoint',
  pot: 'file_type_powerpoint',
  ppsx: 'file_type_powerpoint',
  pps: 'file_type_powerpoint',
  txt: 'file_type_txt',
  xml: 'file_type_xml',
  default: 'file_type_default',
};

Object.freeze(EXTENSION_ICONS);

export const DOCUMENTS_TABLE_COLUMNS = [
  { field: 'title', headerName: 'Name', customCell: true, width: '500px' },
  {
    field: 'createdBy',
    headerName: 'Owner',
    valuesStyle: TABLE_DETAILS_STYLE,
  },
  {
    field: 'createdAt',
    headerName: 'Date Added',
    valuesStyle: TABLE_DETAILS_STYLE,
    width: '210px',
  },
  {
    field: 'docSize',
    headerName: 'File Size',
    valuesStyle: TABLE_DETAILS_STYLE,
    width: '150px',
  },
];

export const DOCUMENTS_ACTION_TYPES = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  DELETE: 'DELETE',
  ADD: 'ADD',
  DOWNLOAD: 'DOWNLOAD',
  RENAME: 'RENAME',
};

export const FOLDER_ACTION_TYPES = {
  EDIT: 'EDIT_FOLDER',
  DELETE: 'DELETE',
  ADD: 'ADD_TO_FOLDER',
};

const DOCUMENTS_ACTIONS = {
  [DOCUMENTS_ACTION_TYPES.DOWNLOAD]: {
    name: 'Download',
    type: DOCUMENTS_ACTION_TYPES.DOWNLOAD,
    predicate: (row: IDisplayableDocument) => !row.document.isFolder,
  },
  [DOCUMENTS_ACTION_TYPES.SET_NOTIFICATION]: {
    name: 'Set Notification',
    type: DOCUMENTS_ACTION_TYPES.SET_NOTIFICATION,
    predicate: (row: IDisplayableDocument) => !row.document.isFolder,
  },
  [DOCUMENTS_ACTION_TYPES.DELETE]: {
    name: 'Delete File',
    type: DOCUMENTS_ACTION_TYPES.DELETE,
    predicate: (row: IDisplayableDocument) => !row.document.isFolder && !row.document.fileObject.readOnly,
  },
  [DOCUMENTS_ACTION_TYPES.RENAME]: {
    name: 'Edit Name',
    type: DOCUMENTS_ACTION_TYPES.RENAME,
    predicate: (row: IDisplayableDocument) => !row.document.isFolder && !row.document.fileObject.readOnly,
  },
};

const FOLDER_ACTIONS = {
  [FOLDER_ACTION_TYPES.ADD]: {
    name: 'Add Files',
    type: FOLDER_ACTION_TYPES.ADD,
    predicate: (row: IDisplayableDocument) => row.document.isFolder,
  },
  [FOLDER_ACTION_TYPES.EDIT]: {
    name: 'Edit Name',
    type: FOLDER_ACTION_TYPES.EDIT,
    predicate: (row: IDisplayableDocument) => row.document.isFolder,
  },
  [FOLDER_ACTION_TYPES.DELETE]: {
    name: 'Delete Folder',
    type: FOLDER_ACTION_TYPES.DELETE,
    predicate: (row: IDisplayableDocument) => row.document.isFolder,
  },
};

export const ADMIN_DOCUMENTS_ACTIONS = [
  FOLDER_ACTIONS[FOLDER_ACTION_TYPES.ADD],
  FOLDER_ACTIONS[FOLDER_ACTION_TYPES.EDIT],
  FOLDER_ACTIONS[FOLDER_ACTION_TYPES.DELETE],
  DOCUMENTS_ACTIONS[DOCUMENTS_ACTION_TYPES.DOWNLOAD],
  DOCUMENTS_ACTIONS[DOCUMENTS_ACTION_TYPES.SET_NOTIFICATION],
  DOCUMENTS_ACTIONS[DOCUMENTS_ACTION_TYPES.RENAME],
  DOCUMENTS_ACTIONS[DOCUMENTS_ACTION_TYPES.DELETE],
];

export const VIEW_ONLY_DOCUMENTS_ACTIONS = [
  DOCUMENTS_ACTIONS[DOCUMENTS_ACTION_TYPES.DOWNLOAD],
  DOCUMENTS_ACTIONS[DOCUMENTS_ACTION_TYPES.SET_NOTIFICATION],
];

const EMPTY_QUERY_PARAMS = <Params>{ folderId: null };

export const DOCUMENTS_TABS: ITab<DocumentsRouteNames>[] = [
  {
    name: 'Company',
    route: DocumentsRouteNames.company,
    description: 'Company Documents can be viewed and downloaded by all stakeholders.',
    queryParams: EMPTY_QUERY_PARAMS,
  },
  {
    name: 'Personal',
    route: DocumentsRouteNames.personal,
    description: 'Personal Documents can only be viewed and downloaded by you.',
    queryParams: EMPTY_QUERY_PARAMS,
  },
];

export const ESOP_TAB: ITab<DocumentsRouteNames> = {
  name: 'ESOP',
  route: DocumentsRouteNames.esop,
  description: 'ESOP documents can be viewed and downloaded by all stakeholders.',
  queryParams: EMPTY_QUERY_PARAMS,
};

export const DOCUMENTS_SECTION_TABS = [...DOCUMENTS_TABS, ESOP_TAB];
