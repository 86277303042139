import { CAP_TABLE_TABLE_ACTION_TYPES } from '@app/shareholder-management/types/enums/cap-table-action-type.enum';

export const PERSONAL_ROLES = ['Investor', 'Founder', 'Employee'];

export const STAKEHOLDER_ACTION_TYPE = {
  INVITE: 'INVITE',
  RESEND_INVITE: 'RESEND_INVITE',
  REVOKE_ACCESS: 'REVOKE_ACCESS',
  GRANT_ACCESS: 'GRANT_ACCESS',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
};

export const REVOKE_COPYRIGHT = {
  HEADER: 'Revoke Stakeholder Access',
  BUTTON: 'Revoke Access',
};

export const ACCESS_COPYRIGHT = {
  HEADER: 'Grant Stakeholder Access',
  BUTTON: 'Grant Access',
};

export const CAP_TABLE_TABLE_ACTIONS = Object.freeze({
  VIEW: { name: 'View more', type: CAP_TABLE_TABLE_ACTION_TYPES.VIEW },
  EDIT: { name: 'Edit', type: CAP_TABLE_TABLE_ACTION_TYPES.EDIT },
  DELETE: { name: 'Delete grant', type: CAP_TABLE_TABLE_ACTION_TYPES.DELETE },
  VIEW_DOCUMENTS: { name: 'View Documents', type: CAP_TABLE_TABLE_ACTION_TYPES.VIEW_DOCUMENTS },
});

export const TABLE_ROW_HEIGHT = '56px';
export const TABLE_HEADER_ROW_HEIGHT = '56px';
export const TABLE_FOOTER_HEIGHT = '40px';

export const SHARES_TOOLTIPS = {
  tagAlongRight:
    'If a majority shareholder sells their stake, Tag Along rights give this shareholder the right to join the transaction and sell their minority stake in the company.',
  dragAlongRight:
    'If the majority stake in the company is sold at any point, Drag Along rights give this shareholder the right to force minority owners to sell their shares at the same price.',
  votingRight: 'This shareholder has rights to vote on corporate matters dictated in their shareholder agreement.',
  liquidationPreference:
    'In the case that the company needs to be liquidated, this shareholder has priority in receiving their money ahead of those without liquidation preference.',
  preemptiveRight:
    'This shareholder can buy future additional shares before shares are made available to the general public.',
};

export const RIGHTS_MAX_LENGTH = 255;
