<app-select
  *wvLet="selectedOption$ | async as selectedOption"
  class="mode"
  icon="selector-chevron"
  [options]="options"
  [selectedValue]="selectedOption"
  (select)="handleModeChange($event)"
>
  <ng-template ngIf="selectedOption">
    <div class="container">
      <div class="caption">Select View Mode</div>
      <div class="option">
        {{ selectedOption.name }}
      </div>
    </div>
  </ng-template>
</app-select>
