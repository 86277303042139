import { Component, Input, OnInit } from '@angular/core';

import { IAvatarListElement } from '@app/shared/types/interfaces/avatars-list-element.interface';

@Component({
  selector: 'wevestr-stakeholders-avatars-list',
  templateUrl: './stakeholders-avatars-list.component.html',
  styleUrls: ['./stakeholders-avatars-list.component.scss'],
})
export class StakeholdersAvatarsListComponent implements OnInit {
  @Input()
  public displayCount = 3;

  @Input()
  public stakeholders: IAvatarListElement[] = [];

  public moreThanDisplayCountLabel = 0;

  public ngOnInit(): void {
    const stakeholdersCount = this.stakeholders.length;

    if (stakeholdersCount > this.displayCount) {
      this.moreThanDisplayCountLabel = stakeholdersCount - this.displayCount;
    }
  }
}
