<span *ngIf="stakeholders | isEmptyArray">-</span>
<div *ngIf="stakeholders | isNotEmptyArray" class="participants-avatars-container flex-row">
  <app-avatar
    *ngFor="let stakeholder of stakeholders | slice: 0:displayCount"
    [name]="stakeholder?.displayName"
    [image]="stakeholder?.avatarUrl"
    class="participant-avatar"
  >
  </app-avatar>
  <app-avatar
    *ngIf="moreThanDisplayCountLabel"
    class="participant-avatar"
    [userElipsisPlaceholder]="moreThanDisplayCountLabel"
  ></app-avatar>
</div>
