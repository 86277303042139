<section class="side-card">
  <div class="side-card__cross padding-left-l padding-top-l">
    <mat-icon mat-dialog-close class="cursor-pointer" svgIcon="cross"></mat-icon>
  </div>
  <div class="side-card__person margin-bottom-m">
    <app-avatar
      class="avatar"
      [size]="96"
      [name]="shareholder.displayName"
      [image]="shareholder.profile?.avatarUrl"
    ></app-avatar>
    <div class="app-h4">{{ shareholder.displayName }}</div>
    <div class="app-h5">{{ shareholder.companyRole }}</div>
  </div>
  <div class="side-card__row">
    <span class="app-body">{{ entityType }}<ng-container *ngIf="showInformationWord"> Information</ng-container></span>
  </div>
  <div class="side-card__info">
    <ng-content></ng-content>
  </div>
</section>
