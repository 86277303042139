import { SettingsRouteNames } from '@app/settings/types/enums';
import { SECTION_PATH } from '@app/shared/utils/constants/paths.constants';
import { Section } from '@app/shared/types/enums/section.enum';

const COMPANY_SETTINGS_SECTION_PATH = SECTION_PATH[Section.companySettings];

export const COMPANY_SETTINGS_TABS_PATH: Partial<Record<SettingsRouteNames, string[]>> = {
  [SettingsRouteNames.COMPANY_GENERAL]: [...COMPANY_SETTINGS_SECTION_PATH, SettingsRouteNames.COMPANY_GENERAL],
  [SettingsRouteNames.COMPANY_STARTUP_COOP]: [
    ...COMPANY_SETTINGS_SECTION_PATH,
    SettingsRouteNames.COMPANY_STARTUP_COOP,
  ],
  [SettingsRouteNames.COMPANY_IMPORT]: [...COMPANY_SETTINGS_SECTION_PATH, SettingsRouteNames.COMPANY_IMPORT],
  [SettingsRouteNames.COMPANY_CAP_TABLE]: [...COMPANY_SETTINGS_SECTION_PATH, SettingsRouteNames.COMPANY_CAP_TABLE],
  [SettingsRouteNames.COMPANY_EXTERNAL_SOURCES]: [
    ...COMPANY_SETTINGS_SECTION_PATH,
    SettingsRouteNames.COMPANY_EXTERNAL_SOURCES,
  ],
};
