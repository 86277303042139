<div class="app-body-bold margin-bottom-s">{{ label }}<span *ngIf="required" class="red-color">*</span></div>
<div class="chip-list">
  <div class="chip is-align-items-center margin-right-s" *ngFor="let stakeholder of stakeholders; let index = index">
    <app-avatar
      [name]="stakeholder.name"
      [image]="stakeholder.profile?.avatarUrl || stakeholder.avatarUrl"
      [size]="24"
      class="app-avatar chip__image"
    >
    </app-avatar>
    <div class="app-body">{{ stakeholder.displayName}}</div>
    <mat-icon svgIcon="cross" class="chip__remove-icon" (click)="onRemove(index)"></mat-icon>
  </div>
</div>
