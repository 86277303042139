import { IAuthCopyright } from '@app/auth/types/interfaces/auth-copyright.interface';

export const DEFAULT_LOGIN_COPY: IAuthCopyright = {
  title: 'Login',
  text: 'Login to the WE.VESTR platform.',
  buttonText: 'Sign in',
};

export const DEFAULT_VERIFY_EMAIL_CONTENT = {
  title: 'Check Your Email',
  message: 'We have sent you password recover instruction to your email.',
  isVerificationNeeded: true,
  forgotCredentialsLink: '/auth/forgot-credentials',
  loginLink: '/auth/login',
};

export const PASSWORD_INPUT_TYPE = 'password';

export const ONBOARIND_LOGIN_TEXT = 'Please login to proceed with the creation of the company.';

export const ROUTES_WITH_SIDEBAR: string[] = [
  '/auth/login',
  '/auth/forgot-credentials',
  '/auth/check-mail',
  '/auth/approvals/email-change/old',
  '/auth/approvals/email-change/new',
  '/auth/reset-password',
];

export const ROUTES_WITH_INTERIM_SCREEN: string[] = ['/auth/reset-password-success'];

export const ROUTES_WITH_RIGHT_SIDE_COVER: string[] = ['/auth/expired-invite', '/auth/access-revoked'];

export const ABOUT_WEVESTR =
  'WE.VESTR is a brilliant way to manage your equity matters. The plafrom gives shareholders the tools and insights they need at all times, and also keeps our employees engaged via the ESOP feature.';

export const QUOTE = '— Bram Medema, Co-CEO of HirePort';
