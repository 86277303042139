<div
  class="interim-item flex-column align-items-center"
>
  <div class="flex-column align-items-center margin-bottom-l">
    <p class="app-h4-bold interim-item__title margin-bottom-l">{{ title }}</p>
    <div class="app-body interim-item__description margin-bottom-l" [innerHTML]="description"></div>
    <button
      class="button primary-button app-body"
      (click)="handleContinue()"
    >
      Continue to Platform
    </button>
  </div>
  <div class="interim-item__img"></div>
</div>
