import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/shared/types/classes';
import { IStakeholder } from '@app/types/interfaces/stakeholder';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { AppVersionService, AppVersion } from '@app/shared/services/app-version.service';

const MIN_CLICK_COUNT_TO_SHOW_VERSION = 10;

@Component({
  selector: 'app-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.scss'],
})
export class GreetingComponent implements OnInit {
  @Input() public user: User;
  @Input() public companyId: number;

  public isDashboard: boolean;
  public isParticipantOverview: boolean;
  public isVersionShown = false;
  public readonly frontEndVersion = environment.appVersion;
  public backEndVersion$: Observable<AppVersion>;
  public clickCount = 0;

  public currentUser: IStakeholder;

  constructor(public router: Router, private appVersionService: AppVersionService) {}

  public ngOnInit(): void {
    this.backEndVersion$ = this.appVersionService.version$();
    this.getCurrentUser();
  }

  public ngOnChanges(): void {
    this.isDashboard = this.router.url.includes('dashboard');
    this.isParticipantOverview = this.router.url.endsWith('/participant');
  }

  public getCurrentUser(): void {
    if (this.user && this.user.profile && this.user.profile.shareholders) {
      this.currentUser = this.user.profile.shareholders.find((shareholder) => shareholder.companyId === this.companyId);
    }
  }

  public handleNameClicked(): void {
    if (this.isVersionShown) {
      return;
    }
    if (++this.clickCount >= MIN_CLICK_COUNT_TO_SHOW_VERSION) {
      this.isVersionShown = true;
    }
  }
}
