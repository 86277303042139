import { REQUIRED_ERROR } from '@app/forms/constants';

export const SECURITY_FORM_ERRORS = {
  REQUIRED: REQUIRED_ERROR,
  MIN_LENGTH: 'Minimum 8 symbols long',
  PATTERN: 'Password should contain at least 1: lowercase, uppercase, number',
  MATCH: 'Passwords should be the same',
};

export const SECURITY_FORM_DETAILS = {
  PASSWORD: {
    label: 'Please type your password',
  },
  CONFIRM_PASSWORD: {
    label: 'Please re-type your password',
  },
};

export const BUTTONS = {
  CONTINUE: 'Continue',
  BACK: 'Back',
};

export const MIN_PASSWORD_LENGTH = 8;
export const CHECK_PASSWORD_STRENGTH_DELAY = 500;

export const PASSWORD_LENGTH = {
  MEDIUM: 8,
  GREAT: 12,
};
