import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { User } from '@app/shared/types/classes';
import { ITopControlOption } from '@app/types/interfaces/top-control-option.interface';
import { IStakeholder } from '@app/types/interfaces/profile.interface';

@Component({
  selector: 'app-top-controls',
  templateUrl: './top-controls.component.html',
  styleUrls: ['./top-controls.component.scss'],
})
export class TopControlsComponent implements OnInit {
  @Input() public user: User;
  @Input() public companyId: number;
  @Input() public options: ITopControlOption[];
  @Output() public logout = new EventEmitter<void>();
  @Output() public startProductTour = new EventEmitter<void>();
  public currentUser: IStakeholder;
  public fullName: string;

  public ngOnInit(): void {
    this.getCurrentUser();
  }

  public showDropdown(element: HTMLElement): void {
    element.classList.add('is-active');
  }
  public hideDropdown(element: HTMLElement): void {
    element.classList.remove('is-active');
  }

  public getCurrentUser(): void {
    if (this.user && this.user.profile && this.user.profile.shareholders) {
      this.currentUser = this.user.profile.shareholders.find((shareholder) => shareholder.companyId === this.companyId);
      this.fullName =
        this.currentUser?.firstName && this.currentUser?.lastName
          ? `${this.currentUser.firstName} ${this.currentUser.lastName}`
          : '';
    }
  }
}
