import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IStepData } from '@app/shared/types/interfaces';

@Injectable()
export class StepperService {
  private _currentStepData$ = new BehaviorSubject<IStepData>(null);
  private _currentStep$ = new BehaviorSubject<number>(null);
  private _currentSubstep$ = new BehaviorSubject<number>(null);

  public get currentStepData$(): Observable<IStepData> {
    return this._currentStepData$.asObservable();
  }

  public setCurrentStepData(stepData: IStepData): void {
    this._currentStepData$.next(stepData);
  }

  public get currentStep$(): Observable<number> {
    return this._currentStep$.asObservable();
  }

  public setCurrentStep(step: number): void {
    this._currentStep$.next(step);
  }

  public get currentSubstep$(): Observable<number> {
    return this._currentSubstep$.asObservable();
  }

  public setCurrentSubstep(step: number): void {
    this._currentSubstep$.next(step);
  }

  public goBack(): void {
    this._currentStep$.next(this._currentStep$.value - 1);
  }

  public goNext(): void {
    this._currentStep$.next(this._currentStep$.value + 1);
  }

  public goNextSubstep(): void {
    this._currentSubstep$.next(this._currentSubstep$.value + 1);
  }

  public goBackSubstep(): void {
    this._currentSubstep$.next(this._currentSubstep$.value - 1);
  }
}
