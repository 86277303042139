<button
  [class]="classes"
  [ngClass]="{disabled: disabled, loading: loading}"
  (click)="handleClick($event)"
  [disabled]="disabled || loading ? true : null"
  [type]="isSubmit ? 'submit' : 'button'"
  [routerLink]="routerLink"
  [queryParamsHandling]="routerLink ? '' : 'merge'"
  [attr.data-id]="buttonDataId"
>
  <mat-icon *ngIf="icon" class="icon" [svgIcon]="icon"></mat-icon>
  <div class="button__content"><ng-content></ng-content></div>
  <div class="button__progress" #progress></div>
</button>
