import { Component } from '@angular/core';
import { loginPath } from '@app/shared/utils/constants/paths.constants';

@Component({
  selector: 'wevestr-accepted-invite',
  templateUrl: './accepted-invite.component.html',
  styleUrls: ['./accepted-invite.component.scss'],
})
export class AcceptedInviteComponent {
  public loginPath = loginPath;
}
