import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

import {
  BUTTONS,
  CHECK_PASSWORD_STRENGTH_DELAY,
  MIN_PASSWORD_LENGTH,
  PASSWORD_LENGTH,
  SECURITY_FORM_DETAILS,
  SECURITY_FORM_ERRORS,
} from '@app/components/components/security-form/security-form.constants';
import { PASSWORD_ICONS } from '@app/shared/utils/constants/auth.constants';
import { openInNewTab } from '@app/shared/utils/helpers/common.helpers';
import { getStringValidatorByRegExp, PASSWORD_VALIDATION_RULES, passwordMatcherValidator } from '@app/auth/validators';
import { WevestrFormsModule } from '@app/forms/wevestr-forms.module';
import { WevestrDirectivesModule } from '@app/directives/wevestr-directives.module';
import { IGNORE_TAB_INDEX } from '@app/shared/utils/constants/common.constants';

enum PasswordStrength {
  NONE = 'none',
  WEAK = 'weak',
  MEDIUM = 'medium',
  GREAT = 'great',
}

interface SecurityForm {
  password: FormControl<string | null>;
  confirmPassword: FormControl<string | null>;
  confirmPolicy: FormControl<boolean | null>;
}

const checkedValidator = (control: AbstractControl): ValidationErrors | null => {
  const isValid = control.value;
  return isValid ? null : { required: true };
};

@Component({
  selector: 'wevestr-security-form',
  templateUrl: './security-form.component.html',
  styleUrls: ['./security-form.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, WevestrFormsModule, WevestrDirectivesModule, MatIconModule],
})
export class SecurityFormComponent implements OnInit {
  @Input() public password: string;
  @Input() public privacyPolicyUrl: string;
  @Input() public cookieUrl: string;
  @Input() public termsAndConditionsUrl: string;
  @Input() public isBackButtonVisible = true;
  @Input() public continueButtonTitle: string;

  @Output() public saveInfo = new EventEmitter<{ password: string }>();
  @Output() public previousStep = new EventEmitter<{ password: string }>();
  @Output() public privacyPolicyOpened = new EventEmitter<void>();
  @Output() public termsAndConditionsOpened = new EventEmitter<void>();
  @Output() public cookiePolicyOpened = new EventEmitter<void>();

  public readonly ERRORS = SECURITY_FORM_ERRORS;
  public readonly SECURITY_FORM_DETAILS = SECURITY_FORM_DETAILS;
  public readonly BUTTONS = BUTTONS;
  public readonly PASSWORD_ICONS = PASSWORD_ICONS;
  public readonly PASSWORD_STRENGTH = PasswordStrength;
  public readonly ignoreTabIndex = IGNORE_TAB_INDEX;

  constructor(private formBuilder: FormBuilder) {}

  public form: FormGroup<SecurityForm>;
  public showPassword = false;
  public passwordStrength$: Observable<PasswordStrength>;
  public wasPasswordSetBefore = false;

  public ngOnInit(): void {
    this.form = this.getForm(this.password);
    this.passwordStrength$ = this.getPasswordStrength$();
  }

  private getForm(password: string): FormGroup<SecurityForm> {
    this.wasPasswordSetBefore = !!password;
    return this.formBuilder.group(
      {
        password: [
          password || '',
          [
            Validators.required,
            Validators.minLength(MIN_PASSWORD_LENGTH),
            getStringValidatorByRegExp(PASSWORD_VALIDATION_RULES, 'badPasswordCharacters'),
          ],
        ],
        confirmPassword: ['', Validators.required],
        confirmPolicy: [false, checkedValidator],
      },
      { validators: [passwordMatcherValidator] },
    );
  }

  private getPasswordStrength$(): Observable<PasswordStrength> {
    return this.form.controls.password.valueChanges.pipe(
      debounceTime(CHECK_PASSWORD_STRENGTH_DELAY),
      startWith(this.password),
      map((value) => {
        const length = value?.length;
        let strength = PasswordStrength.NONE;
        switch (true) {
          case length === 0:
            strength = PasswordStrength.NONE;
            break;
          case length < PASSWORD_LENGTH.MEDIUM:
            strength = PasswordStrength.WEAK;
            break;
          case length < PASSWORD_LENGTH.GREAT:
            strength = PasswordStrength.MEDIUM;
            break;
          case length >= PASSWORD_LENGTH.GREAT:
            strength = PasswordStrength.GREAT;
            break;
        }
        return strength;
      }),
      startWith(PasswordStrength.NONE),
    );
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public submitSecurity(): void {
    const isFormValid = this.form.valid;
    if (isFormValid) {
      const { password } = this.form.getRawValue();
      this.saveInfo.emit({ password });
    }
  }

  public handleBack(): void {
    const { password } = this.form.getRawValue();
    this.previousStep.emit({ password });
  }

  public handlePrivacyPolicyClick($event: Event): void {
    $event.preventDefault();
    this.privacyPolicyOpened.emit();
    openInNewTab(this.privacyPolicyUrl);
  }

  public handleTermsAndConditionsClick($event: Event): void {
    $event.preventDefault();
    this.termsAndConditionsOpened.emit();
    openInNewTab(this.termsAndConditionsUrl);
  }

  public handleCookiePolicyClick($event: Event): void {
    $event.preventDefault();
    this.cookiePolicyOpened.emit();
    openInNewTab(this.cookieUrl);
  }
}
