import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { components } from '@app/forms/components';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { directives } from '@app/forms/directives';
import { WevestrDirectivesModule } from '@app/directives/wevestr-directives.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AsFormGroupPipe } from '@app/forms/pipes/as-form-group.pipe';
import { WevestrPipesModule } from '@app/pipes/wevestr-pipes.module';

@NgModule({
  declarations: [...components, ...directives, AsFormGroupPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    MatIconModule,
    WevestrDirectivesModule,
    WevestrPipesModule,
    MatDatepickerModule,
    MatInputModule,
    MatTooltipModule,
  ],
  exports: [ReactiveFormsModule, FormsModule, NgxMaskModule, ...components, ...directives, AsFormGroupPipe],
})
export class WevestrFormsModule {}
