import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'wevestr-interim-screen',
  templateUrl: './interim-screen.component.html',
  styleUrls: ['./interim-screen.component.scss'],
  standalone: true,
})
export class InterimScreenComponent {
  @Input() public title: string;
  @Input() public description: string;

  @Output() public continue = new EventEmitter<void>();

  public handleContinue(): void {
    this.continue.emit();
  }
}
