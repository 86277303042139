import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ISidenavOption } from '@app/types/interfaces';
import { ICompany } from '@app/shareholder-management/types/interfaces';
import { LegalDocumentsService } from '@app/shared/services/legal-documents.service';
import { UserService } from '@app/shared/services';
import { compareById } from '@app/shared/utils/helpers/compare.helpers';
import { AnalyticsService } from '@app/shared/services/analytics.service';
import { AnalyticsEvent } from '@app/shared/types/enums/analytics-event.enum';
import { openInNewTab } from '@app/shared/utils/helpers/common.helpers';
import { AnalyticsJourney } from '@app/shared/types/enums/analytics-journey.enum';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent implements OnInit {
  @Input() public collapsed: boolean;
  @Input() public activeOption: string;
  @Input() public options: ISidenavOption[];
  @Input() public canToggleSidenav: boolean;
  @Input() public defaultCompany: number;
  @Input('companies') public set _companies(value: ICompany[]) {
    if (value) {
      this.companies = value;
      this.setDefaultCompany();
    }
  }
  @Output() public toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
  @Output() public changeCompany: EventEmitter<ICompany> = new EventEmitter<ICompany>();
  @Output() public createCompany: EventEmitter<void> = new EventEmitter<void>();

  public companyControl = new FormControl<ICompany | null>(null);
  public companies: ICompany[];
  public isSuperAdminOfAnyCompany: boolean;
  public readonly compareById = compareById;
  public privacyPolicy: string;
  public cookiePolicy: string;
  public readonly frontEndVersion: string = environment.appVersion;

  constructor(
    private docsService: LegalDocumentsService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.isSuperAdminOfAnyCompany = this.userService.isSuperAdminOfAnyCompany();
    this.privacyPolicy = this.docsService.privacyPolicy;
    this.cookiePolicy = this.docsService.cookiePolicy;
  }

  public handleToggleSidenav(): void {
    if (this.canToggleSidenav) {
      this.toggleSidenav.emit();
    }
  }

  public handleChangeCompany(company: ICompany): void {
    this.analyticsService.track(AnalyticsEvent.SelectCompany);
    this.changeCompany.emit(company);
  }

  public handleCreateCompany(): void {
    this.analyticsService.track(AnalyticsEvent.AddNewCompany, { journey: AnalyticsJourney.Onboarding });
    this.createCompany.emit();
  }

  public isOptionActive(optionLink: string, activeOption: string): boolean {
    return activeOption.startsWith(optionLink);
  }

  public handlePrivacyPolicyClick(event: Event): void {
    event.preventDefault();
    this.analyticsService.track(AnalyticsEvent.ViewPrivacyPolicy, {
      pageName: this.route.snapshot.data.pageName,
    });
    openInNewTab(this.privacyPolicy);
  }

  private setDefaultCompany(): void {
    if (this.defaultCompany) {
      const company = this.companies.find((_company: ICompany) => _company.id === this.defaultCompany);
      this.companyControl.setValue(company ? company : this.companies[0]);
    } else {
      this.companyControl.setValue(this.companies[0]);
    }
  }
}
