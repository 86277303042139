import { StatusCodes } from 'http-status-codes';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ToastService } from '@app/shared/services/toast.service';
import { UserService } from '@app/shared/services/user.service';
import { INTERNAL_SERVER_ERROR_MESSAGE } from '@app/shared/utils/constants/toast-messages.constants';
import { loginPath, paymentNeededPath } from '@app/shared/utils/constants/paths.constants';
import { AuthService } from '@app/auth/services/auth.service';
import { ERROR_MESSAGES } from '@app/shared/utils/constants/error.constants';
import { isObject, isString } from '@app/shared/utils/helpers/common.helpers';

@Injectable({
  providedIn: 'root',
})
export class ApiResponseInterceptor implements HttpInterceptor {
  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === StatusCodes.BAD_REQUEST) {
          this.handleBadRequestStatus(error);
        } else if (error.status === StatusCodes.FORBIDDEN) {
          this.handleForbiddenStatus(error);
        } else if (error.status === StatusCodes.UNAUTHORIZED) {
          this.handleUnauthorizedStatus(error);
        } else {
          this.toastService.error(INTERNAL_SERVER_ERROR_MESSAGE);
        }

        return throwError(error);
      }),
    );
  }

  private handleUnauthorizedStatus(error: HttpErrorResponse): void {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    const isRevoked = error.error.message?.isRevoked;

    if (!isRevoked) {
      this.authService
        .logOut()
        .pipe(tap(() => this.userService.removeCurrentCompany()))
        .subscribe(() => this.router.navigate(loginPath));
    }
  }

  private handleBadRequestStatus({ error }: HttpErrorResponse): void {
    const errorBody = error?.message;

    if (isString(errorBody)) {
      this.toastService.error(errorBody);
    } else if (!isObject(errorBody)) {
      this.toastService.error(INTERNAL_SERVER_ERROR_MESSAGE);
    }
  }

  handleForbiddenStatus({ error }: HttpErrorResponse): void {
    if (error?.message === ERROR_MESSAGES.PAYMENT_STATUS_BAD) {
      this.toastService.error(error.message);
      this.router.navigate(paymentNeededPath);
    } else if (error.message?.companyId) {
      if (!this.authService.isLoggedIn()) {
        return;
      }

      this.authService
        .logOut()
        .pipe(tap(() => this.userService.removeCurrentCompany()))
        .subscribe(() => this.router.navigate(loginPath));
    }
  }
}
